import * as React from "react";
import TitleBar from "../../../common/PageTitleBar";
import CaseStudyIntroBox from "./IntroBox";
import CaseStudyMedia from "./CaseStudyMedia";
import ParallaxText from "../../../common/ParallaxContent";
import { CaseStudy, CaseStudy3 } from "../../../../classes/CaseStudy";
import ParallaxHero from "../../../common/ParallaxHero";
import RelatedCaseStudies from "../GelbertCaseStudy/WeHelpSec/WeHelpSec";

const CaseStudyV1 = ({
  work,
  combined,
}: {
  work: CaseStudy;
  combined: (CaseStudy | CaseStudy3)[];
}) => {
  const heroImage = work?.heroImage;
  const challengeText = work?.content?.challenge?.text;
  const clientName = work?.clientName;
  const resultHero = work?.content?.results?.image;
  const mediaGallery = work.gallery;
  return (
    <>
      <TitleBar title={clientName} />
      {heroImage ? <ParallaxHero image={heroImage} /> : null}
      {challengeText && (
        <CaseStudyIntroBox
          intro={challengeText}
          tags={work.services}
          logo={work?.logo?.full}
          website={work?.website}
          industry={work?.industry}
        />
      )}
      <CaseStudyMedia media={mediaGallery} />
      {resultHero && (
        <ParallaxText
          title="Results"
          content={work?.content?.results?.text}
          button={{ show: false }}
          image={resultHero}
        />
      )}
      <RelatedCaseStudies moreWork={combined} currCaseStudy={clientName} />
    </>
  );
};

export default CaseStudyV1;
