import * as React from "react";
import { CaseStudyGallery } from "../../../../classes/CaseStudy";
import { Img } from "wmk-image";
import CustomGallery from "./CaseStudyMediaGallery";

const CaseStudyMedia = ({ media }: { media: CaseStudyGallery }) => {
  const mediaGroup = media?.images;
  const ret = Array.isArray(mediaGroup)
    ? mediaGroup.map((group: { mediaType: string; imageGallery: Img[] }, i) => {
        const type = group.mediaType;
        const stuff = group.imageGallery;
        if (type === "Image Gallery" && Array.isArray(stuff) && stuff.length) {
          return <CustomGallery img={stuff} key={type + i} />;
        } else {
          return null;
        }
      })
    : null;
  return <>{ret}</>;
};

export default CaseStudyMedia;
