import * as React from "react";
import Layout from "../components/layout/MainLayout";
import { graphql } from "gatsby";
import { WmkSeo } from "wmk-seo";
import CaseStudyV1 from "../components/routes/CaseStudy/v1/CaseStudy";
import GelbertCaseStudy from "../components/routes/CaseStudy/GelbertCaseStudy/GelbertCaseStudy";
import {
  CaseStudyFields,
  CaseStudyV3Fields,
} from "../fragments/nodeCaseStudyFields";
import { CaseStudies, CaseStudy, CaseStudy3 } from "../classes/CaseStudy";
import { SiteMetadataFields } from "../fragments/nodeSIteMetadataFields";

const CaseStudyNode = ({ data }: CaseStudyQuery) => {
  const { work, workV3, allWork, allWorkV3, site } = data;
  const thisWork = work ? new CaseStudy(work) : new CaseStudy3(workV3);
  const combined = new CaseStudies([...allWork.edges, ...allWorkV3.edges]);
  // remove any schema case studies
  const noSchema = (entry: CaseStudy | CaseStudy3) => {
    return !entry.slug.includes("schema");
  };
  const combinedListNoSchema = combined.list.filter(noSchema);
  combined.list = combinedListNoSchema;
  return (
    <Layout>
      <WmkSeo.Meta
        title={"title" in thisWork ? thisWork?.title : thisWork?.pageTitle ? thisWork?.pageTitle : thisWork.clientName}
        path="/work/"
        slug={thisWork.slug}
        description={`Case study of project completed by Watermark for ${thisWork?.clientName}. ${thisWork?.summary}`}
        ogImageUrl={thisWork?.heroImage?.url("ogImage")}
        twitterImageUrl={thisWork?.heroImage?.url("twitterImage")}
        twitterHandle={site?.siteMetadata?.author}
        siteTitle={site?.siteMetadata?.title}
        baseUrl={site?.siteMetadata?.baseUrl}
        isSiteTitleDisplayed={false}
      />
      {"title" in thisWork ? (
        <CaseStudyV1 work={thisWork} combined={combined.list} />
      ) : (
        <GelbertCaseStudy work={thisWork} allWork={combined.list} />
      )}
    </Layout>
  );
};
export default CaseStudyNode;

export interface CaseStudyQuery {
  data: {
    workV3: CaseStudyV3Fields;
    work: CaseStudyFields;
    allWork: { edges: { node: CaseStudyFields }[] };
    allWorkV3: { edges: { node: CaseStudyV3Fields }[] };
    site: SiteMetadataFields;
  };
}

export const query = graphql`
  query caseStudyQuery($slug: String!, $services: [String]) {
    site {
      ...NodeSiteMetadataFields
    }
    workV3: contentfulCaseStudy(slug: { eq: $slug }, title: { ne: "schema" }) {
      ...NodeCaseStudyV3Fields
    }
    work: contentfulWorkCaseStudyOld(slug: { eq: $slug }) {
      ...NodeCaseStudyFields
    }
    allWorkV3: allContentfulCaseStudy(
      sort: { fields: launchDate, order: DESC }
      filter: { services: { in: $services }, slug: { ne: $slug } }
    ) {
      edges {
        node {
          ...NodeCaseStudyV3ExcerptFields
        }
      }
    }
    allWork: allContentfulWorkCaseStudyOld(
      sort: { fields: launchDate, order: DESC }
      filter: { services: { in: $services }, slug: { ne: $slug } }
    ) {
      edges {
        node {
          ...NodeCaseStudyExcerptFields
        }
      }
    }
  }
`;
