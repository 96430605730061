import React from "react";
import TitleBar from "../../../common/PageTitleBar";
import { ParallaxProvider } from "react-scroll-parallax";
import styled from "styled-components";
import CaseStudyDetailsBar from "./DetailsBar";
import Theme from "../../../../vars/ThemeOptions";
import { colors } from "../../../../vars/palette";
import { CaseStudy, CaseStudy3 } from "../../../../classes/CaseStudy";
import { WmkImage } from "wmk-image";
import { RichTextReact } from "wmk-rich-text";
import { options } from "../../../blocks/RichText/richTextOptions";
import RelatedCaseStudies from "./WeHelpSec/WeHelpSec";
import { Col, Container, Row } from "react-bootstrap";

const Wrap = styled.div`
  h2,
  h5 {
    font-family: ${Theme.font.head};
    color: ${colors.hex("secondary")};
    font-weight: bold;
    text-transform: uppercase;
  }
  h2 {
    margin-bottom: 2rem;
  }
  h5 {
    margin-bottom: 1rem;
  }
  .copy-wrap {
    padding: 2rem;
    border-left: 4px solid ${colors.hex("secondary")};
  }
  .row {
    margin: 3rem 0;
    &.neg-margin {
      margin: -3rem 0;
    }
    &.no-margin {
      margin: 0;
    }
  }
`;
const HeroWrap = styled.div`
  .gatsby-image-wrapper {
    width: 100%;
  }
`;

const DoubleImageContainer = styled(Container)`
  padding-left: 0 !important;
  padding-right: 0 !important;
  .col-md-6 {
    padding-left: 0;
    padding-right: 0;
  }
  .row {
    margin: 0;
  }
  .double-left-image-col {
    padding-right: 0.5%;
  }
  .double-right-image-col {
    padding-left: 0.5%;
  }
  @media screen and (max-width: 767px) {
    .double-left-image-col {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 2%;
    }
    .double-right-image-col {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const TripleImageContainer = styled(Container)`
  padding-left: 0 !important;
  padding-right: 0 !important;
  .col {
    padding-left: 0;
    padding-right: 0;
  }
  .row {
    margin: 0;
  }
  .triple-left-image-col {
    padding-left: 0;
    padding-right: 1%;
    .row:first-child {
      margin-bottom: 2%;
    }
  }
  .triple-right-image-col {
    padding-right: 0;
    padding-left: 0;
    .gatsby-image-wrapper-constrained {
      height: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    .triple-left-image-col {
      padding-left: 0;
      padding-right: 0;
      .row {
        margin-bottom: 2%;
      }
    }
    .triple-right-image-col {
      height: fit-content !important;
      .gatsby-image-wrapper-constrained {
        height: auto;
      }
    }
  }
`;

const GelbertCaseStudy = ({
  work,
  allWork,
}: {
  work: CaseStudy3;
  allWork: (CaseStudy | CaseStudy3)[];
}) => {

  const imageNumber = work?.centerImages?.length;

  return (
    <ParallaxProvider>
      <Wrap>
        <TitleBar
          title={work.clientName}
          style={{ paddingTop: 0, marginBottom: "-1.5rem" }}
        />
        <HeroWrap>
          <WmkImage image={work.heroImage} />
        </HeroWrap>
        <CaseStudyDetailsBar logo={work?.logo} services={work.solutions} />
        <ParallaxProvider>
          <RichTextReact content={work.content} options={options} />
        </ParallaxProvider>
        {imageNumber === 1 && <WmkImage image={work.centerImages[0]} />}
        {imageNumber === 2 && (
          <DoubleImageContainer fluid>
            <Row>
              <Col className="double-left-image-col" xs={12} md={6}>
                <WmkImage image={work.centerImages[0]} />
              </Col>
              <Col className="double-right-image-col" xs={12} md={6}>
                <WmkImage image={work.centerImages[1]} />
              </Col>
            </Row>
          </DoubleImageContainer>
        )}
        {imageNumber === 3 && (
          <TripleImageContainer fluid>
            <Row>
              <Col
                md={7}
                xs={12}
                className="triple-left-image-col"
              >
                <Row>
                  <Col>
                    <WmkImage image={work.centerImages[0]} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <WmkImage image={work.centerImages[1]} />
                  </Col>
                </Row>
              </Col>
              <Col md={5} xs={12} className="triple-right-image-col">
                <WmkImage image={work.centerImages[2]} />
              </Col>
            </Row>
          </TripleImageContainer>
        )}
        <ParallaxProvider>
          <RichTextReact content={work.bottomContent} options={options} />
        </ParallaxProvider>
        <RelatedCaseStudies
          moreWork={allWork.slice(0, 4)}
          currCaseStudy={work.clientName}
        />
      </Wrap>
    </ParallaxProvider>
  );
};

export default GelbertCaseStudy;
