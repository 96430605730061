import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Theme from "../../../../vars/ThemeOptions";
import SubHeading from "../../../common/typography/SubHeading";
import ReactMarkdown from "react-markdown";
import { colors } from "../../../../vars/palette";
import { Img, WmkImage } from "wmk-image";
import { WmkLink } from "wmk-link";

const IntroWrap = styled(Container)`
  position: relative;
  z-index: 10;
  margin-bottom: -50px;
  padding: 2rem;
  margin-top: 50px;
  border-left: 4px solid ${colors.hex("secondary")};
  h5 {
    font-family: ${Theme.font.head};
    color: ${colors.hex("secondary")};
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  .details,
  .industry {
    font-size: 1rem;
    line-height: 2;
    img {
      margin-bottom: 1rem;
      object-fit: contain !important;
    }
    strong {
      font-weight: bold;
    }
  }
  .industry {
    margin-bottom: 0.5rem;
  }
  .client-logo {
    max-width: 150px;
  }
  .intro {
    @media screen and (max-width: 740px) {
      margin-bottom: 1.5rem;
    }
  }
  .tags,
  .industry {
    line-height: 1.5;
  }
  .tags {
    margin-top: 1rem;
  }
`;

type CaseStudyIntroBoxProps = {
  intro: string;
  tags: string[];
  industry: string;
  logo: Img;
  website: string;
};

const CaseStudyIntroBox = ({
  intro,
  tags,
  industry,
  logo,
  website,
}: CaseStudyIntroBoxProps) => {
  return (
    <IntroWrap>
      <Row style={{ paddingBottom: "1rem" }}>
        <Col sm={12} md={7} lg={8} className="intro">
          <SubHeading text="Background" />
          {industry && (
            <div className="industry">
              <strong>Client Industry:</strong> {industry}
            </div>
          )}
          <ReactMarkdown>{intro}</ReactMarkdown>
        </Col>
        <Col sm={12} md={5} lg={4} className="details">
          <h5>Details</h5>
          {website ? (
            <WmkLink to={website} target="_blank">
              <WmkImage image={logo} className="client-logo" />
            </WmkLink>
          ) : (
            <WmkImage image={logo} className="client-logo" />
          )}
          {tags && tags.length ? (
            <div className="tags">
              <strong>Services:</strong> {tags.join(", ")}
            </div>
          ) : null}
        </Col>
      </Row>
    </IntroWrap>
  );
};

export default CaseStudyIntroBox;
