import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { WMKGradient } from "../../../../vars/palette";
import { WmkLink } from "wmk-link";
import { WmkImage, Img } from "wmk-image";
import { colors } from "../../../../vars/colors";

const Wrap = styled.div`
  ${WMKGradient.grad()}
  min-height: 3rem;
  border-top: 3px solid white;
  border-bottom: 3px solid white;
  color: white;
  overflow: hidden;
  padding: 5px 0;
  position: relative;
  z-index: 100;
  .gatsby-image-wrapper {
    max-height: 7rem;
    img {
      object-fit: contain !important;
    }
  }
  .centered {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    a {
      width: 100%;
      &.ball-corporation-case-study {
        width: 70%;
      }
      &.wills .gatsby-image-wrapper {
        width: 100%;
      }
    }
    span {
      font-weight: bold;
    }
  }
  .logo {
    padding-bottom: 1.5rem;
  }
  .solution-links {
    flex-wrap: wrap;
    a {
      color: ${colors.white.getHex()};
      text-decoration: none;
      &:hover {
        color: ${colors.purple.getHex()};
      }
    }
  }
  p {
    margin-bottom: 0;
  }
`;

const CaseStudyDetailsBar = ({
  logo,
  services,
  website,
}: {
  logo: Img;
  services: string[];
  website?: string;
}) => {
  const servicesHash = {
    Branding: "/solutions/branding",
    Digital: "/solutions/inbound-marketing",
    "Digital Marketing": "/solutions/inbound-marketing",
    ESG: "/solutions/corporate-communications",
    "Email Campaign": "/solutions/inbound-marketing",
    "Inbound Marketing": "/solutions/inbound-marketing",
    Infographics: "/solutions/branding",
    Logo: "/solutions/branding",
    "Marketing Automation": "/solutions/inbound-marketing",
    Print: "/solutions/branding",
    SEO: "/solutions/inbound-marketing",
    Web: "/solutions/web-design",
    "Web Design": "/solutions/web-design",
    "Web Development": "/solutions/web-design",
    "Website Development": "/solutions/web-design",
    schema: "schema",
  };

  return (
    <Wrap>
      <Container>
        <Row className="no-margin" style={{ padding: "1rem 0" }}>
          <Col xs={12} sm={2} className="centered logo">
            {website ? (
              <WmkLink to={website} target="blank">
                <WmkImage image={logo} />
              </WmkLink>
            ) : (
              <WmkImage image={logo} />
            )}
          </Col>
          <Col xs={12} sm={10} className="centered solution-links">
            <p>
              <span>Solutions: </span>
            </p>
            {services.map((service, i) => {
              return (
                <>
                  <WmkLink to={servicesHash[service]}>
                    <p>
                      {service}
                      {i < services.length - 1 ? ", " : " "}
                    </p>
                  </WmkLink>
                </>
              );
            })}
            {/* {services.join(", ")} */}
          </Col>
        </Row>
      </Container>
    </Wrap>
  );
};

export default CaseStudyDetailsBar;
